import {request} from '../../util/Request'
import Error from "../../util/Error";

const SPOT_INBOUND_LOAD_DOCUMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

const DEFAULT_SPOT_INBOUND_ORDER_STATE = {
    supplier_carrier_id: null,
    supplier_compound_id: null,
    from_location_id: null,
    to_location_id: null,
    price_id: null,
    route_id: null,
    price_period_id: null,
    document: {...SPOT_INBOUND_LOAD_DOCUMENT_STATE},
    documents: [],
    ids: [],
    errors: null,
    comments: null,
}

const DEFAULT_FORM_STATE = {
    orders: [], // DEFAULT_SPOT_INBOUND_ORDER_STATE
}

export default {
    data() {
        return {
            spotInboundSelectedOrders: [],
            groupedSpotInboundOrders: [],
            selectedSpotInboundOrderLength: 0,
            spotInboundLoadFormState: {...DEFAULT_FORM_STATE},
            dropdowns: {
                carrierSuppliers: [],
                compoundSuppliers: [],
                currencies: [],
            },
        }
    },
    methods: {
        handleSpotInboundLoadAddClick() {
            const self = this
            let from_to_keys = [] // array of from location id and to Location ids
            _.map(self.spotInboundSelectedOrders, (item) => {
                from_to_keys.push(`${item.from_location.id} ${item.to_location.id}`)
            })

            _.map(_.uniqBy(from_to_keys), (item, index) => {
                const locations = item.split(' ')
                const items = _.filter(self.spotInboundSelectedOrders, (order) => (
                    order.from_location.id === locations[0] &&
                    order.to_location.id === locations[1]
                ))

                self.groupedSpotInboundOrders.push({
                    from_location: items[0].from_location,
                    to_location: items[0].to_location,
                    items: items,
                    does_m_l_approval_required: (_.filter(items, (o) => o.does_m_l_approval_required > 0).length > 0),
                    models: _.uniqBy(items, 'model.id').map(i => i.model.id),
                    brands: _.uniqBy(items, 'brand.id').map(i => i.brand.id)
                })

                self.spotInboundLoadFormState.orders.push({
                    ids: items.map((order) => order.id), // OrderIds
                    supplier_carrier_id: null,
                    supplier_compound_id: null,
                    from_location_id: locations[0],
                    to_location_id: locations[1],
                    price_id: null,
                    route_id: null,
                    price_period_id: null,
                    loading_factors: items.length,
                    errors: '',
                    document: {...SPOT_INBOUND_LOAD_DOCUMENT_STATE},
                    documents: [],
                    comments: null,
                })
            })

            this.getSpotInboundLoadSupplierCarrier()
            this.getSpotInboundLoadSupplierCompound()
            this.spotInboundLoadFormVisible = true
        },
        async handleSpotInboundLoadSubmit() {
            if(this.validateSpotInboundLoad())
               return;

            try {
                const response = await request({
                    url: 'inbound/loads/create',
                    method: 'post',
                    data: {
                        ...this.spotInboundLoadFormState,
                        is_spot: 1
                    },
                })

                this.loadCreateSuccess()
                this.handleSpotInboundLoadFormOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.validationError()
                    return false
                }

                this.handleServerError(error)
            }
        },
        async getSpotInboundLoadSupplierCarrier() {
            try {
                const response = await request({
                    url: '/dropdowns/orders/matched/location/carrier/suppliers',
                    method: "post",
                    data: {
                        orders: this.spotInboundSelectedOrders.map(item => item.id),
                        is_spot: 1
                    }
                })

                const {data} = response
                this.dropdowns.carrierSuppliers = data
                _.map(this.groupedSpotInboundOrders, (group, index) => {
                    _.map(data, (supplier) => {
                        // this.spotInboundLoadFormState.orders[index].supplier_carrier_id = data[0].id
                        if (_.filter(supplier.prices, ({route}) => (route.from_location.id === group.from_location.id && route.to_location.id === group.to_location.id)).length > 0) {
                            const price = _.filter(supplier.prices, ({route}) => (route.from_location.id === group.from_location.id && route.to_location.id === group.to_location.id))[0];
                            const price_period = _.filter(price.price_periods, (item) => (item.price_spl * 1) > 0);
                            this.spotInboundLoadFormState.orders[index].supplier_carrier_id = supplier.id
                            this.spotInboundLoadFormState.orders[index].errors = '';

                            if(price_period.length > 0 && price_period[0].price_spl > 0 && price_period[0].currency) {
                                this.spotInboundLoadFormState.orders[index].price_id = price.id;
                                this.spotInboundLoadFormState.orders[index].route_id = price.route.id;
                                this.spotInboundLoadFormState.orders[index].price_period_id = price_period[0].id;
                            } else {
                                this.spotInboundLoadFormState.orders[index].errors = this.$t('validation.invalidPricePeriod');
                            }
                        }
                    })
                })

            } catch (e) {
                this.dropdowns.carrierSuppliers = []
            }
        },
        async getSpotInboundLoadSupplierCompound() {
            try {
                const response = await request({
                    url: '/dropdowns/suppliers/compound',
                    method: "post",
                    data: {
                        orders: this.spotInboundSelectedOrders.map(item => item.id)
                    }
                })

                const {data} = response
                this.dropdowns.compoundSuppliers = data
                if(data && data.length > 0) {
                    _.map(this.spotInboundLoadFormState.orders, (item, index) => {
                        this.spotInboundLoadFormState.orders[index].supplier_compound_id = data[0].id
                    })
                }
            } catch (e) {
                this.dropdowns.compoundSuppliers = []
            }
        },
        validateSpotInboundLoad() {
            let errors = false

            _.map(this.spotInboundLoadFormState.orders, (item, index) => {
                this.spotInboundLoadFormState.orders[index].errors = '';

                if(!item.supplier_carrier_id || !item.supplier_compound_id) {
                    errors = true
                    this.spotInboundLoadFormState.orders[index].errors = this.$t('msc.requiredFieldsMissing')+'<br>'
                }
            })

            return errors;
        },
        handleSpotInboundRowSelection(item, rowIndex, event) {
            let response = true;
            if (item.actual_production_date !== null && item.manufacturer_invoice_date !== null && item.vin_number !== null) {
                const index = _.findIndex(this.spotInboundSelectedOrders, {id: item.id});
                if (index >= 0) { // if already exist then delete
                    setTimeout(() => this.$refs.orderTable.unselectRow(rowIndex), 200)
                    delete this.spotInboundSelectedOrders[index]
                    this.selectedSpotInboundOrderLength = this.selectedSpotInboundOrderLength - 1
                } else { // else add new
                    this.spotInboundSelectedOrders.push(item)
                    setTimeout(() => this.$refs.orderTable.selectRow(rowIndex), 200)
                    this.selectedSpotInboundOrderLength = this.selectedSpotInboundOrderLength + 1
                }
            } else {
                response = false
            }

            this.spotInboundSelectedOrders = _.compact(this.spotInboundSelectedOrders)
            return response;
        },
        handleSpotInboundLoadFormOperationClose(refreshList = true) {
            this.spotInboundLoadFormState = {...DEFAULT_FORM_STATE}
            this.spotInboundLoadFormState.orders.length = 0
            this.spotInboundLoadFormVisible = false
            this.groupedSpotInboundOrders.length = 0
            this.spotInboundSelectedOrders.length = 0
            this.selectedSpotInboundOrderLength = 0
            this.$refs.orderTable.clearSelected()
            this.formErrors = new Error({})

            if(refreshList) { // to handle call from refreshList (When page change)
                this.handlePriorityFormOperationClose(true)
                this.refreshList()
            }
        },
        handleAddUpdateSpotInboundLoadDocumentClick(groupIndex) {
            this.spotInboundLoadFormState.orders[groupIndex].document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.spotInboundLoadFormState.orders[groupIndex].document.document || this.spotInboundLoadFormState.orders[groupIndex].document.document.length <= 0 || !this.spotInboundLoadFormState.orders[groupIndex].document.document.id) {
                this.spotInboundLoadFormState.orders[groupIndex].document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.spotInboundLoadFormState.orders[groupIndex].document.title || _.trim(this.spotInboundLoadFormState.orders[groupIndex].document.title).length <= 2) {
                this.spotInboundLoadFormState.orders[groupIndex].document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.spotInboundLoadFormState.orders[groupIndex].documents, (document) => {
                if(document.token !== this.spotInboundLoadFormState.orders[groupIndex].document.token) {
                    if(document.title === this.spotInboundLoadFormState.orders[groupIndex].document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.spotInboundLoadFormState.orders[groupIndex].document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if(this.spotInboundLoadFormState.orders[groupIndex].document.error) return false;

            const entity = this.spotInboundLoadFormState.orders[groupIndex].document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.spotInboundLoadFormState.orders[groupIndex].documents, {token: entity.token});

            if (this.spotInboundLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.spotInboundLoadFormState.orders[groupIndex].documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.spotInboundLoadFormState.orders[groupIndex].documents.push(entity)
            }

            this.spotInboundLoadFormState.orders[groupIndex].document = {...SPOT_INBOUND_LOAD_DOCUMENT_STATE, token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}
        },
        handleDeleteSpotInboundLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.spotInboundLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.spotInboundLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.spotInboundLoadFormState.orders[groupIndex].documents.splice(index, 1);
            }
        },
        handleEditSpotInboundLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.spotInboundLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.spotInboundLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.spotInboundLoadFormState.orders[groupIndex].document = {...this.spotInboundLoadFormState.orders[groupIndex].documents[index]};
            }
        },
        resetSpotInboundLoadDocument(groupIndex) {
            this.spotInboundLoadFormState.orders[groupIndex].document = {...SPOT_INBOUND_LOAD_DOCUMENT_STATE}
            this.spotInboundLoadFormState.orders[groupIndex].document.error = false;
        },
        handleSpotSupplierChange(value, group, index) {
            this.spotInboundLoadFormState.orders[index].errors = null
            this.spotInboundLoadFormState.orders[index].route_id = null
            this.spotInboundLoadFormState.orders[index].price_id = null
            this.spotInboundLoadFormState.orders[index].price_period_id = null
            const {from_location, to_location, brands: groupBrands, models: groupModels} = group
            if(!_.find(this.dropdowns.carrierSuppliers, {id: value})) {
                return;
            }

            const {prices} = _.find(this.dropdowns.carrierSuppliers, {id: value})
            const price = _.find(prices, ({brands, route, models}) => {
                let doesSelectedOrderContainsPriceModel = 0
                let doesSelectedOrderContainsPriceBrand = 0

                _.map(models, (m) => {
                    if (groupModels.includes(m.id)) {
                        doesSelectedOrderContainsPriceModel++;
                    }
                })

                _.map(brands, (m) => {
                    if (groupBrands.includes(m.id)) {
                        doesSelectedOrderContainsPriceBrand++;
                    }
                })

                return (
                    route.from_location.id === from_location.id &&
                    route.to_location.id === to_location.id &&
                    groupModels.length === doesSelectedOrderContainsPriceModel &&
                    groupBrands.length === doesSelectedOrderContainsPriceBrand
                )
            });

            if (price) {
                const price_period = _.filter(price.price_periods, (item) => (item.price_spl * 1) > 0);
                this.spotInboundLoadFormState.orders[index].supplier_carrier_id = value;
                this.spotInboundLoadFormState.orders[index].errors = '';

                if (price_period.length > 0 && price_period[0].price_spl > 0 && price_period[0].currency) {
                    this.spotInboundLoadFormState.orders[index].price_id = price.id;
                    this.spotInboundLoadFormState.orders[index].route_id = price.route.id;
                    this.spotInboundLoadFormState.orders[index].price_period_id = price_period[0].id;
                } else {
                    this.spotInboundLoadFormState.orders[index].errors = this.$t('validation.invalidPricePeriod');
                }
            } else {
                this.spotInboundLoadFormState.orders[index].price_id = null;
                this.spotInboundLoadFormState.orders[index].route_id = null;
                this.spotInboundLoadFormState.orders[index].price_period_id = null;
                this.spotInboundLoadFormState.orders[index].errors = this.$t('validation.invalidPrice');
                this.priceNotFound();
            }
        },
    },
}
