import {request} from '../../util/Request'
import Error from "../../util/Error";

const INBOUND_LOAD_DOCUMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

const DEFAULT_INBOUND_ORDER_STATE = {
    supplier_carrier_id: null,
    supplier_compound_id: null,
    from_location_id: null,
    to_location_id: null,
    price_period_id: null,
    assignee_id: null,
    assignee_required: 0,
    route_id: null,
    price_id: null,
    loading_factors: 0,
    loading_factor_note: 0,
    document: {...INBOUND_LOAD_DOCUMENT_STATE},
    documents: [],
    ids: [],
    errors: null,
    comments: null,
}

const DEFAULT_FORM_STATE = {
    orders: [], // DEFAULT_INBOUND_ORDER_STATE
}

export default {
    data() {
        return {
            inboundSelectedOrders: [],
            groupedInboundOrders: [],
            selectedInboundOrderLength: 0,
            inboundLoadFormState: {...DEFAULT_FORM_STATE},
            dropdowns: {
                carrierSuppliers: [],
                compoundSuppliers: [],
            },
        }
    },
    methods: {
        handleInboundLoadAddClick() {
            const self = this
            let from_to_keys = [] // array of from location id and to Location ids
            _.map(self.inboundSelectedOrders, (item) => {
                from_to_keys.push(`${item.from_location.id} ${item.to_location.id}`)
            })

            _.map(_.uniqBy(from_to_keys), (item, index) => {
                const locations = item.split(' ')
                const items = _.filter(self.inboundSelectedOrders, (order) => (
                    order.from_location.id === locations[0] &&
                    order.to_location.id === locations[1]
                ))

                self.groupedInboundOrders.push({
                    from_location: items[0].from_location,
                    to_location: items[0].to_location,
                    items: items,
                    does_m_l_approval_required: (_.filter(items, (o) => o.does_m_l_approval_required > 0).length > 0),
                    models: _.uniqBy(items, 'model.id').map(i => i.model.id),
                    brands: _.uniqBy(items, 'brand.id').map(i => i.brand.id),
                })

                self.inboundLoadFormState.orders.push({
                    ids: items.map((order) => order.id), // OrderIds
                    supplier_carrier_id: null,
                    supplier_compound_id: null,
                    from_location_id: locations[0],
                    to_location_id: locations[1],
                    assignee_id: null,
                    assignee_required: 0,
                    price_id: null,
                    route_id: null,
                    loading_factors: 0,
                    errors: '',
                    loading_factor_note: null,
                    document: {...INBOUND_LOAD_DOCUMENT_STATE},
                    documents: [],
                    comments: null,
                    total_assigned: 0,
                    current_volume: 0,
                    max_volume: 0,
                })
            })

            this.getInboundOrderNearLocationSupplierCarrier()
            this.getInboundOrderNearLocationSupplierCompound()
            this.inboundLoadFormVisible = true
        },
        async handleInboundLoadSubmit() {
            if (this.validateInboundLoad())
                return;

            try {
                const response = await request({
                    url: 'inbound/loads/create',
                    method: 'post',
                    data: {...this.inboundLoadFormState},
                })

                this.loadCreateSuccess()
                this.handleInboundLoadFormOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.validationError()
                    return false
                }

                this.handleServerError(error)
            }
        },
        async getInboundOrderNearLocationSupplierCarrier() {
            try {
                const response = await request({
                    url: '/dropdowns/orders/matched/location/carrier/suppliers',
                    method: "post",
                    data: {
                        orders: this.inboundSelectedOrders.map(item => item.id)
                    }
                })

                const {data} = response
                this.dropdowns.carrierSuppliers = data
                _.map(this.groupedInboundOrders, (group, index) => {
                    let suppliers = [];
                     _.map(data, (supplier) => {
                         if (_.filter(supplier.prices, ({route}) => (route.from_location.id === group.from_location.id && route.to_location.id === group.to_location.id)).length > 0) {
                             const price = _.filter(supplier.prices, ({route}) => (route.from_location.id === group.from_location.id && route.to_location.id === group.to_location.id))[0];
                             suppliers.push({
                                 supplier_id: supplier.id,
                                 total: price.total,
                                 assigned: price.assigned,
                                 has_volume: price.has_volume,
                                 current_volume: price.current_volume,
                                 price: price
                             })
                         }
                     })

                    if (suppliers.length > 0) {
                        this.inboundLoadFormState.orders[index].supplier_carrier_id = _.sortBy(suppliers, ['current_volume'])[0].supplier_id
                        this.inboundLoadFormState.orders[index].total_assigned = _.sortBy(suppliers, ['current_volume'])[0].assigned
                        this.inboundLoadFormState.orders[index].current_volume = _.sortBy(suppliers, ['current_volume'])[0].current_volume
                        this.inboundLoadFormState.orders[index].max_volume = _.sortBy(suppliers, ['current_volume'])[0].has_volume
                    }
                })
            } catch (e) {
                this.dropdowns.carrierSuppliers = []
            }
        },
        async getInboundOrderNearLocationSupplierCompound() {
            try {
                const response = await request({
                    url: '/dropdowns/orders/matched/location/compound/suppliers',
                    method: "post",
                    data: {
                        orders: this.inboundSelectedOrders.map(item => item.id)
                    }
                })

                const {data} = response
                this.dropdowns.compoundSuppliers = data
                if (data && data.length > 0) {
                    _.map(this.inboundLoadFormState.orders, (item, index) => {
                        this.inboundLoadFormState.orders[index].supplier_compound_id = data[0].id
                    })
                }
            } catch (e) {
                this.dropdowns.compoundSuppliers = []
            }
        },
        validateInboundLoad() {
            let errors = false

            _.map(this.inboundLoadFormState.orders, (item, index) => {
                this.inboundLoadFormState.orders[index].errors = '';
                if (!item.supplier_carrier_id || !item.supplier_compound_id || (!item.assignee_id && item.assignee_required)) {
                    errors = true
                    this.inboundLoadFormState.orders[index].errors = this.$t('msc.requiredFieldsMissing') + '<br>'
                }

                if (item.loading_factors <= 0) {
                    errors = true
                    this.inboundLoadFormState.orders[index].errors = this.inboundLoadFormState.orders[index].errors + this.$t('validation.loadingFactorInvalid')
                }
            })

            return errors;
        },
        handleInboundRowSelection(item, rowIndex, event) {
            let response = true;
            if (item.actual_production_date !== null && item.manufacturer_invoice_date !== null && item.vin_number !== null && !item.added_in_load_at) {
                const index = _.findIndex(this.inboundSelectedOrders, {id: item.id});
                if (index >= 0) { // if already exist then delete
                    setTimeout(() => this.$refs.orderTable.unselectRow(rowIndex), 200)
                    delete this.inboundSelectedOrders[index]
                    this.selectedInboundOrderLength = this.selectedInboundOrderLength - 1
                } else { // else add new
                    this.inboundSelectedOrders.push(item)
                    setTimeout(() => this.$refs.orderTable.selectRow(rowIndex), 200)
                    this.selectedInboundOrderLength = this.selectedInboundOrderLength + 1
                }
            } else {
                response = false
            }

            this.inboundSelectedOrders = _.compact(this.inboundSelectedOrders)
            return response;
        },
        handleInboundLoadFormOperationClose(refreshList = true) {
            this.inboundLoadFormState = {...DEFAULT_FORM_STATE}
            this.inboundLoadFormState.orders.length = 0
            this.inboundLoadFormVisible = false
            this.groupedInboundOrders.length = 0
            this.inboundSelectedOrders.length = 0
            this.selectedInboundOrderLength = 0
            this.$refs.orderTable.clearSelected()
            this.formErrors = new Error({})

            if (refreshList) { // to handle call from refreshList (When page change)
                this.handlePriorityFormOperationClose(true)
                this.refreshList()
            }
        },
        handleSupplierChange(value, group, index) {
            this.inboundLoadFormState.orders[index].loading_factor_note = null
            this.inboundLoadFormState.orders[index].total_assigned = 0
            this.inboundLoadFormState.orders[index].current_volume = 0
            this.inboundLoadFormState.orders[index].max_volume = 0
            this.inboundLoadFormState.orders[index].price_id = null

            const {from_location, to_location, brands: groupBrands, models: groupModels} = group
            if(!_.find(this.dropdowns.carrierSuppliers, {id: value})){
                return;
            }

            const {prices} = _.find(this.dropdowns.carrierSuppliers, {id: value})
            const price = _.find(prices, ({brands, route, models}) => {
                let doesSelectedOrderContainsPriceModel = 0
                let doesSelectedOrderContainsPriceBrand = 0

                _.map(models, (m) => {
                    if (groupModels.includes(m.id)) {
                        doesSelectedOrderContainsPriceModel++;
                    }
                })

                _.map(brands, (m) => {
                    if (groupBrands.includes(m.id)) {
                        doesSelectedOrderContainsPriceBrand++;
                    }
                })

                return (
                    route.from_location.id === from_location.id &&
                    route.to_location.id === to_location.id &&
                    groupModels.length === doesSelectedOrderContainsPriceModel &&
                    groupBrands.length === doesSelectedOrderContainsPriceBrand
                )
            });

            if (price) {
                this.inboundLoadFormState.orders[index].route_id = price.route.id
                this.inboundLoadFormState.orders[index].price_id = price.id
                this.inboundLoadFormState.orders[index].price_period_id = price.price_periods[0].id
                this.inboundLoadFormState.orders[index].loading_factors = price.loading_factors
                this.inboundLoadFormState.orders[index].total_assigned = price.assigned
                this.inboundLoadFormState.orders[index].current_volume = price.current_volume
                this.inboundLoadFormState.orders[index].max_volume = price.has_volume

                if (price.loading_factors > 0 && group.items.length > price.loading_factors) {

                    this.inboundLoadFormState.orders[index].loading_factor_note = (Math.ceil(group.items.length / price.loading_factors)) + ' ' + this.$t('msc.loadsWillBeCreated')

                    if (!Number.isInteger(group.items.length / price.loading_factors)) {
                        this.inboundLoadFormState.orders[index].assignee_required = 1;
                        this.inboundLoadFormState.orders[index].loading_factor_note = this.inboundLoadFormState.orders[index].loading_factor_note + '<br />' + this.$t('msc.additionalSupervisorApprovalNeeded')
                    }
                } else if (price.loading_factors > 0 && group.items.length < price.loading_factors) {
                    this.inboundLoadFormState.orders[index].assignee_required = 1;
                    this.inboundLoadFormState.orders[index].loading_factor_note = (this.inboundLoadFormState.orders[index].loading_factor_note != null) ?
                    this.inboundLoadFormState.orders[index].loading_factor_note + '<br />' + this.$t('msc.additionalSupervisorApprovalNeeded') : this.$t('msc.additionalSupervisorApprovalNeeded');
                }
            } else {
                this.inboundLoadFormState.orders[index].price_id = null
                this.priceNotFound()
            }
        },
        handleAddUpdateInboundLoadDocumentClick(groupIndex) {
            this.inboundLoadFormState.orders[groupIndex].document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.inboundLoadFormState.orders[groupIndex].document.document || this.inboundLoadFormState.orders[groupIndex].document.document.length <= 0 || !this.inboundLoadFormState.orders[groupIndex].document.document.id) {
                this.inboundLoadFormState.orders[groupIndex].document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.inboundLoadFormState.orders[groupIndex].document.title || _.trim(this.inboundLoadFormState.orders[groupIndex].document.title).length <= 2) {
                this.inboundLoadFormState.orders[groupIndex].document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.inboundLoadFormState.orders[groupIndex].documents, (document) => {
                if (document.token !== this.inboundLoadFormState.orders[groupIndex].document.token) {
                    if (document.title === this.inboundLoadFormState.orders[groupIndex].document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.inboundLoadFormState.orders[groupIndex].document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.inboundLoadFormState.orders[groupIndex].document.error) return false;

            const entity = this.inboundLoadFormState.orders[groupIndex].document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.inboundLoadFormState.orders[groupIndex].documents, {token: entity.token});

            if (this.inboundLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.inboundLoadFormState.orders[groupIndex].documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.inboundLoadFormState.orders[groupIndex].documents.push(entity)
            }

            this.inboundLoadFormState.orders[groupIndex].document = {
                ...INBOUND_LOAD_DOCUMENT_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeleteInboundLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.inboundLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.inboundLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.inboundLoadFormState.orders[groupIndex].documents.splice(index, 1);
            }
        },
        handleEditInboundLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.inboundLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.inboundLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.inboundLoadFormState.orders[groupIndex].document = {...this.inboundLoadFormState.orders[groupIndex].documents[index]};
            }
        },
        resetInboundLoadDocument(groupIndex) {
            this.inboundLoadFormState.orders[groupIndex].document = {...INBOUND_LOAD_DOCUMENT_STATE}
            this.inboundLoadFormState.orders[groupIndex].document.error = false;
        },
    },
}
