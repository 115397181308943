<template>
    <div>
        <div v-if="show">
            <div class="card" v-show="operation !== 'detail'">
                <div class="card-header card-header-flex pb-2 s-fixed-header">
                    <div class="w-100 mt-2">
                        <div class="row">
                            <div class="col-3">
                                <h5 class="mt-3 ml-0 mr-3 mb-2">
                                    <strong>
                                        <template v-if="operation === null">
                                            {{$t('title.stock')}}
                                            <span v-if="pagination.total" >({{pagination.total}})</span>
                                        </template>
                                        <template v-else>{{ $t(operationTitle) }}</template>
                                    </strong>
                                </h5>
                            </div>
                            <div class="col-9 text-right">
                                <div v-if="operation === null">
                                    <div class="mt-3">
                                        <!-- Using components -->
                                        <b-input-group class="mt-3">
                                            <b-form-input type="search" class="form-control form-control-sm"
                                                          :placeholder="$t('input.whatAreYouLookingFor')"
                                                          v-on:keyup.enter="handleSearch"
                                                          v-model="search"></b-form-input>
                                            <b-input-group-append>
                                                <!--                                                <b-button @click="setOperation('add')" variant="info" size="sm"-->
                                                <!--                                                          :title="$t('button.title.addNewItem')"-->
                                                <!--                                                          v-if="$global.hasPermission('ordersstore')" v-b-tooltip.hover>-->
                                                <!--                                                    <i class="fe fe-plus"></i> {{$t('button.addNew')}}-->
                                                <!--                                                </b-button>-->
                                                <b-button @click="handleInboundLoadAddClick" variant="secondary"
                                                          size="sm"
                                                          :title="$t('button.title.createInboundLoad')"
                                                          :disabled="selectedInboundOrderLength <= 0"
                                                          v-if="$global.hasPermission('inboundloadsstore')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> {{$t('button.createInboundLoad')}}
                                                    <b-badge variant="dark">{{selectedInboundOrderLength}}</b-badge>
                                                </b-button>
                                                <b-button @click="handleSpotInboundLoadAddClick" variant="primary"
                                                          size="sm" :title="$t('button.title.createSinglePickup')"
                                                          :disabled="selectedSpotInboundOrderLength <= 0"
                                                          v-if="$global.hasPermission('inboundloadsstore')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> {{$t('button.createSinglePickup')}}
                                                    <b-badge variant="dark">{{selectedSpotInboundOrderLength}}</b-badge>
                                                </b-button>
                                                <b-button @click="handlePriority" variant="info" size="sm"
                                                          :title="$t('button.title.priority')"
                                                          :disabled="selectedOrderLength <= 0"
                                                          v-if="$global.hasPermission('ordersupdate')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> {{$t('button.priority')}}
                                                    <b-badge variant="dark">{{selectedOrderLength}}</b-badge>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                          variant="outline-info"
                                                          @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersview')">
                                                    <i class="fa fa-filter"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.exportExcel')"
                                                          variant="outline-info"
                                                          @click="handleExcelExportClick" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersview')">
                                                    <i class="fa fa-file-excel-o"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.resetList')"
                                                          variant="outline-info"
                                                          @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                    <i class="fa fa-refresh"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button variant="warning" size="sm" class="mt-3"
                                              @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </div>
                        </div><!-- /.row -->
                    </div><!-- /.w-100 -->
                </div><!-- /.card-header -->
                <div class="card-body">
                    <div class="orders-table">
                        <b-table hover responsive
                                 :busy="listingLoading"
                                 :items="dataSource"
                                 :fields="columns"
                                 :sort-by.sync="sortField"
                                 selectable
                                 ref="orderTable"
                                 @row-clicked="handleRowClick"
                                 @sort-changed="handleSortChange">
                            <template v-slot:cell(brand_id)="{ detailsShowing, item, index, rowSelected }">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                </template>
                                <a @click="toggleDetails(item)" class="d-inline in-center">
                                    <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                </a>
                                <a-avatar shape="square" :size="32" icon="car"
                                          :title="item.brand ? item.brand.title : ''"
                                          v-if="item.brand.logo && item.brand.logo.download_url" v-b-tooltip.hover
                                          :src="item.brand.logo.system_url+'/thumbnail/32x32/'+item.brand.logo.name2"/>
                                <a-avatar shape="square" :size="32" icon="car" v-b-tooltip.hover
                                          :title="item.brand ? item.brand.title : ''" v-else/>
                                <span class="indicator-added-inbound-load"
                                      v-if="item.load_status < 7 && item.added_in_load_at"
                                      :title="$t('title.inboundAdded')" v-b-tooltip.hover></span>
                                <span class="indicator-added-outbound-load"
                                      v-if="item.load_status >= 10 && item.load_status < 17"
                                      :title="$t('title.outboundAdded')" v-b-tooltip.hover></span>
                            </template>
                            <template v-slot:cell(client_id)="record">
                                {{ (record.item.client ? record.item.client.company_name : '') }}
                            </template>
                            <template v-slot:cell(load_status)="{item}">
                                <ol-status :status="item.load_status"></ol-status>
                                <template v-if="item.client_affected"><br>
                                    <b-badge v-if="item.client_affected_priority === 1" variant="danger"
                                             :title="$t('title.clientAffected') + ' | ' + $t('title.high') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')"
                                             v-b-tooltip.hover>{{$t('title.clientAffected')}}
                                    </b-badge>
                                    <b-badge v-if="item.client_affected_priority === 2" variant="warning"
                                             :title="$t('title.clientAffected') + ' | ' + $t('title.medium') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')"
                                             v-b-tooltip.hover>{{$t('title.clientAffected')}}
                                    </b-badge>
                                    <b-badge v-if="item.client_affected_priority === 3" variant="secondary"
                                             :title="$t('title.clientAffected') + ' | ' + $t('title.low') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')"
                                             v-b-tooltip.hover>{{$t('title.clientAffected')}}
                                    </b-badge>
                                </template>
                                <template v-if="item.load_status == '8' && item.client_tr_at"><br>
                                    <b-badge v-if="item.client_tr_at && !item.client_tr_accept_at" variant="dark">
                                        {{$t('title.deliveryRequest')}}
                                    </b-badge>
                                    <b-badge v-if="item.client_tr_accept_at" variant="dark">
                                        {{$t('title.readyToBeLoaded')}}
                                    </b-badge>
                                </template>
                            </template>
                            <template v-slot:cell(from_location_id)="{item}">
                                <print-ad titled="1" :item="item.from_location"></print-ad>
                            </template>
                            <template v-slot:cell(to_location_id)="{item}">
                                <print-ad titled="1" :item="item.to_location"></print-ad>
                            </template>
                            <template v-slot:cell(model)="record">
                                <div>{{record.item.model ? record.item.model.title : ""}}</div>
                                <div>{{record.item.model ? record.item.model.version_code : ""}}</div>
                            </template>
                            <template v-slot:cell(manufacturer_invoice_date)="record">
                                {{record.item.manufacturer_invoice_date ?
                                $global.utcDateToLocalDate(record.item.manufacturer_invoice_date, 'DD.MM.YYYY') : '' }}
                            </template>
                            <template v-slot:cell(updated_at)="record">
                                {{$global.utcDateToLocalDate(record.item.updated_at) }}
                            </template>
                            <template v-slot:cell(action)="record">
                                <!--                                <a @click="setOperation('edit', record.item.id)"-->
                                <!--                                   :title="$t('button.title.editItem')" v-if="$global.hasPermission('ordersupdate') && record.item.load_status <= 2"-->
                                <!--                                   v-b-tooltip.hover>-->
                                <!--                                    <i class="fe fe-edit"></i>-->
                                <!--                                </a>-->

                                <a @click="handleQualityHoldStatusUpdate(record.item)"
                                   :title="$t('button.title.updateStatus')"
                                   v-if="$global.hasPermission('ordersupdate') && (record.item.load_status == 1 || record.item.load_status == -2)"
                                   v-b-tooltip.hover>
                                    <i class="fa fa-wrench"></i>
                                </a>
                                <a @click="setOperation('detail', record.item.id)"
                                   :title="$t('button.title.detailItem')" v-if="$global.hasPermission('ordersview')"
                                   class="ml-2"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </template>
                            <template v-slot:row-details="{ item }">
                                <b-card>
                                    <b-list-group flush>
                                        <b-list-group-item><strong>{{$t('column.importerNumber')}}</strong>:
                                            {{item.invoice_number_importer}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.invoiceDateImporter')}}</strong>:
                                            {{item.invoice_date_importer}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.inboundSupplierCarrier')}}</strong>:
                                            {{item.supplier_carrier ? item.supplier_carrier.name: ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.inboundSupplierCompound')}}</strong>:
                                            {{item.supplier_compound ? item.supplier_compound.name: ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.outboundSupplierCarrier')}}</strong>:
                                            {{item.outbound_supplier_carrier ? item.outbound_supplier_carrier.name :
                                            ''}}
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <strong>{{$t('title.destinationLocation')}}</strong>:
                                            <template v-if="item.dealer_location">
                                                <span v-if="item.dealer_location.street_no">{{item.dealer_location.street_no}}, </span>
                                                <span v-if="item.dealer_location.street">{{item.dealer_location.street}}, </span>
                                                <span
                                                    v-if="item.dealer_location.city">{{item.dealer_location.city}}, </span>
                                                <span
                                                    v-if="item.dealer_location.zip">{{item.dealer_location.zip}}, </span>
                                                <span v-if="item.dealer_location.country">{{item.dealer_location.country}}</span>
                                            </template>
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.transportRequestAt')}}</strong>:
                                            {{item.client_tr_at ? $global.utcDateToLocalDate(item.client_tr_at) : ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.transportRequestAcceptedAt')}}</strong>:
                                            {{item.client_tr_accept_at ?
                                            $global.utcDateToLocalDate(item.client_tr_accept_at) : ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.comment')}}</strong>: {{item.comments}}
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                        </b-table><!-- /.b-table -->
                        <div class="clearfix">
                            <div class="float-left ">
                                <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                               size="sm"></b-form-select>
                            </div>
                            <div class="float-right">
                                <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                                ></b-pagination>
                            </div><!-- /.pull-right -->
                        </div><!-- /.clearfix -->
                    </div><!-- /.orders-table-->
                    <div class="orders-operation">
                        <a-drawer
                            placement="right"
                            :width="'950px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="handleOperationClose"
                            :visible="operation !== null && operation !== 'detail'"
                            :zIndex="10"
                            :title="$t(operationTitle)"
                        >
                            <form @submit.prevent="handleSubmit" autocomplete="off">
                                <b-row>
                                    <b-col cols="12" v-show="global.pendingRequests > 0">
                                        <a-skeleton active :paragraph="{ rows: 5 }"/>
                                    </b-col>
                                    <b-col cols="12" v-show="global.pendingRequests <= 0">
                                        <b-row>
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.client')+' *'"
                                                    label-for="client_id"
                                                    :invalid-feedback="formErrors.first('client_id')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.clients"
                                                        placeholder=""
                                                        v-model="formFields.client_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('client_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.brand')+' *'"
                                                    label-for="brand_id"
                                                    :invalid-feedback="formErrors.first('brand_id')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.brands"
                                                        placeholder=""
                                                        v-model="formFields.brand_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('brand_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.model')+' *'"
                                                    label-for="model_id"
                                                    :invalid-feedback="formErrors.first('model_id')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="_.filter(dropdowns.models, (item) => item.brand_id === formFields.brand_id)"
                                                        placeholder=""
                                                        v-model="formFields.model_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('model_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.dealer')+' *'"
                                                    label-for="dealer_id"
                                                    :invalid-feedback="formErrors.first('dealer_id')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.dealers"
                                                        placeholder=""
                                                        v-model="formFields.dealer_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('dealer_id'))}]"
                                                        @select="handleDealersSelect"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="8">
                                                <b-form-group
                                                    :label="$t('input.destinationLocation')+' *'"
                                                    label-for="dealer_location_id"
                                                    :invalid-feedback="formErrors.first('dealer_location_id')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.dealersLocations"
                                                        placeholder=""
                                                        v-model="formFields.dealer_location_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('dealer_location_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.fromLocation')+' *'"
                                                    label-for="from_location_id"
                                                    :invalid-feedback="formErrors.first('from_location_id')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.manufacturerLocations"
                                                        placeholder=""
                                                        v-model="formFields.from_location_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('from_location_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.toLocation')+' *'"
                                                    label-for="to_location_id"
                                                    :invalid-feedback="formErrors.first('to_location_id')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.supplierCompoundLocations"
                                                        placeholder=""
                                                        v-model="formFields.to_location_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('to_location_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.orderId')+ ' *'"
                                                    label-for="orderId"
                                                    :invalid-feedback="formErrors.first('order_id')"
                                                >
                                                    <b-form-input
                                                        id="order_id"
                                                        v-model="formFields.order_id"
                                                        type="text"
                                                        :state="((formErrors.has('order_id') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.invoiceNumberImporter')"
                                                    label-for="invoice_number_importer"
                                                    :invalid-feedback="formErrors.first('invoice_number_importer')"
                                                >
                                                    <b-form-input
                                                        id="invoice_number_importer"
                                                        v-model="formFields.invoice_number_importer"
                                                        type="text"
                                                        :state="((formErrors.has('invoice_number_importer') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.manufacturerInvoiceDate')"
                                                    label-for="manufacturer_invoice_date"
                                                    :invalid-feedback="formErrors.first('manufacturer_invoice_date')"
                                                >
                                                    <b-form-datepicker
                                                        placeholder=""
                                                        :reset-button="true"
                                                        v-model="formFields.manufacturer_invoice_date"
                                                        :state="((formErrors.has('manufacturer_invoice_date') ? false : null))"></b-form-datepicker>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.invoiceDateImporter')"
                                                    label-for="invoice_date_importer"
                                                    :invalid-feedback="formErrors.first('invoice_date_importer')"
                                                >
                                                    <b-form-datepicker
                                                        placeholder=""
                                                        :reset-button="true"
                                                        v-model="formFields.invoice_date_importer"
                                                        :state="((formErrors.has('invoice_date_importer') ? false : null))"></b-form-datepicker>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.mpoNumber')"
                                                    label-for="mpo_number"
                                                    :invalid-feedback="formErrors.first('mpo_number')"
                                                >
                                                    <b-form-input
                                                        id="mpo_number"
                                                        v-model="formFields.mpo_number"
                                                        type="text"
                                                        :state="((formErrors.has('mpo_number') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="12">
                                                <b-form-group
                                                    :label="$t('input.comment')"
                                                    label-for="comments"
                                                    :invalid-feedback="formErrors.first('comments')"
                                                >
                                                    <b-form-textarea
                                                        id="comments"
                                                        v-model="formFields.comments"
                                                        :state="((formErrors.has('comments') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-textarea>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </b-col><!--/b-col-->
                                    <div class="drawer-footer">
                                        <b-button
                                            size="sm"
                                            type="submit"
                                            variant="primary"
                                            :disabled="global.pendingRequests > 0"
                                            v-b-tooltip.hover :title="$t('button.title.save')"
                                        >
                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                         size="12px"
                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('button.save')}}
                                        </b-button>
                                        <b-button variant="warning" class="ml-3"
                                                  size="sm" @click="handleOperationClose()"
                                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                        </b-button>
                                    </div>
                                </b-row><!--/b-row-->
                            </form><!--/form-->
                        </a-drawer>
                    </div><!--/.orders-operation-->
                    <div class="filter-container">
                        <a-drawer
                            placement="left"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="filters.visible = !filters.visible"
                            :visible="!operation && filters.visible"
                            :zIndex="10"
                            :title="$t('title.advanceFilters')"
                        >
                            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.brands')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.brands"
                                                placeholder=""
                                                v-model="filters.brands"
                                                @input="handleSelectBrands"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <fieldset aria-describedby="" class="form-group">
                                            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
                                                <b-row>
                                                    <b-col sm="6">
                                                        {{$t('input.models')}}
                                                    </b-col>
                                                    <b-col sm="6" class="text-right">
                                                        <label v-show="filters.brands.length > 0" class="m-0 p-0">
                                                            <input
                                                                v-model="selectAllBrand"
                                                                type="checkbox"
                                                                @change="handleSelectAllModels"/>
                                                            {{$t('input.selectAll')}}
                                                        </label>
                                                    </b-col>
                                                </b-row>
                                            </legend>
                                            <treeselect
                                                :multiple="true"
                                                :options="_.filter(dropdowns.modelsGroupByTitle, (item) => _.includes(filters.brands, item.brand_id))"
                                                placeholder=""
                                                v-model="filters.modelsGroupByTitle"
                                                @input="handleSelectModels"
                                            />
                                        </fieldset>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.code')">
                                            <treeselect
                                                :multiple="true"
                                                :options="_.filter(dropdowns.configCodes, (item) => _.includes(filters.modelsGroupByTitle, item.model_label))"
                                                placeholder=""
                                                v-model="filters.configCodes"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.clients')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.clients"
                                                placeholder=""
                                                v-model="filters.clients"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.dealers')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.dealers"
                                                placeholder=""
                                                v-model="filters.dealers"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.fromLocations')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.locations.map(item => ({...item, label: item.city}))"
                                                placeholder=""
                                                v-model="filters.fromLocations"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.toLocations')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.locations.map(item => ({...item, label: item.city}))"
                                                placeholder=""
                                                v-model="filters.toLocations"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.status')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.status"
                                                placeholder=""
                                                v-model="filters.status"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.dateOfAvailability')">
                                            <b-form-datepicker placeholder="" reset-button
                                                               v-model="filters.manufacturer_invoice_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.fromAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.toAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <div class="drawer-footer">
                                    <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                              class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                        {{$t('button.close')}}
                                    </b-button>
                                    <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.reset')}}
                                    </b-button>
                                    <b-button size='sm' variant="primary" button="submit" type="filled"
                                              :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </form>
                        </a-drawer>
                    </div><!-- /.filter-container -->
                </div><!-- /.card-body-->
            </div><!-- /.card -->
            <div v-if="operation === 'detail'">
                <detail :handle-close-operation="handleOperationClose"></detail>
            </div>
        </div>

        <!-- # Start Inbound Load -->
        <a-drawer
            placement="right"
            :width="'86%'"
            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
            :closable="false"
            @close="handleInboundLoadFormOperationClose"
            :visible="inboundLoadFormVisible"
            :zIndex="10"
            :title="$t('title.createInboundLoad')"
        >
            <form @submit.prevent="handleInboundLoadSubmit" autocomplete="off">
                <b-row v-if="groupedInboundOrders.length > 0">
                    <b-col sm="12" v-for="(group, index) in groupedInboundOrders" :key="index">
                        <b-row>
                            <b-col sm="12" lg="2" md="2" class=" mb-sm-3">
                                <h4 class="d-sm-inline">
                                    <span class="bg-gray-6 ml-2 rounded text-white p-1">
                                        {{$t('title.load')}}#<strong>{{index + 1}}</strong>
                                    </span>
                                </h4>
                                <h4 class="mt-4 ml-2 d-sm-inline">
                                    <span class="bg-light rounded p-1">
                                        <small>{{$t('title.orders')}}-{{group.items.length}}</small>
                                    </span>
                                </h4>
                            </b-col>
                            <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                <dl>
                                    <dt>{{$t('title.fromLocation')}}:</dt>
                                    <dd>
                                        <print-ad :item="group.from_location"></print-ad>
                                    </dd><!--/FromLocation-->

                                    <dt>{{$t('title.toLocation')}}:</dt>
                                    <dd>
                                        <print-ad :item="group.to_location"></print-ad>
                                    </dd><!--/ToLocation-->
                                    <dt>VIN:</dt>
                                    <dd>
                                        <div v-for="(order, index) in group.items">
                                            {{order.vin_number}}
                                        </div>
                                    </dd>
                                </dl>
                            </b-col>
                            <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                <dl>
                                    <dt>{{$t('title.brand')}}:</dt>
                                    <dd>
                                        <div v-for="(order, index) in _.uniqBy(group.items, 'brand.title')">
                                            {{order.brand.title}}
                                        </div>
                                    </dd><!--/brand-->

                                    <dt>{{$t('title.model')}}:</dt>
                                    <dd>
                                        <div v-for="(order, index) in _.uniqBy(group.items, 'model.version_code')">
                                            {{order.model.title}}-{{order.model.version_code}}
                                        </div>
                                    </dd><!--/model-->
                                </dl>
                            </b-col>
                            <b-col sm="12" lg="3" md="3">
                                <div>
                                    <small v-if="inboundLoadFormState.orders[index].errors" class="mb-2 d-block">
                                        <span class="text-danger"
                                              v-html="inboundLoadFormState.orders[index].errors"></span>
                                    </small>
                                </div>
                                <div class="form-group mb-1">
                                    <label class="d-block">{{$t('input.supplierCarrier')}} *</label>
                                    <treeselect
                                        :multiple="false"
                                        :options="_.filter(dropdowns.carrierSuppliers, (supplier) => (
                                                        _.filter(supplier.prices, ({route}) => (
                                                            route.from_location.id === group.from_location.id &&
                                                            route.to_location.id === group.to_location.id
                                                        )
                                                    ).length > 0
                                                )
                                            )
                                        "
                                        placeholder=""
                                        v-model="inboundLoadFormState.orders[index].supplier_carrier_id"
                                        :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id') || formErrors.has('price_id') || formErrors.has('route_id'))}]"
                                        @input="(value, instance) => handleSupplierChange(value, group, index)"
                                    >
                                        <template slot="value-label" slot-scope="{ node }">
                                            <div :title="node.label">
                                                {{ node.label }}
                                            </div>
                                        </template>
                                    </treeselect>
                                    <div class="invalid-feedback">{{formErrors.first('supplier_carrier_id')}}</div>
                                    <div class="invalid-feedback">{{formErrors.first('price_id')}}</div>
                                    <div class="invalid-feedback">{{formErrors.first('route_id')}}</div>
                                    <div class="text-muted" v-if="inboundLoadFormState.orders[index].price_id">
                                        <div>{{$t('title.assignedLoad')}}: {{inboundLoadFormState.orders[index].total_assigned}}</div>
                                        <div>{{$t('title.currentVolume')}}: {{inboundLoadFormState.orders[index].current_volume}}</div>
                                        <div>{{$t('title.maxVolume')}}: {{inboundLoadFormState.orders[index].max_volume}}</div>
                                    </div>
                                </div><!-- /.form-group -->
                                <div class="form-group mb-1">
                                    <label class="d-block">{{$t('input.supplierCompound')}} *</label>
                                    <treeselect
                                        :multiple="false"
                                        :options="dropdowns.compoundSuppliers"
                                        placeholder=""
                                        v-model="inboundLoadFormState.orders[index].supplier_compound_id"
                                        :class="[{'invalid is-invalid': (formErrors.has('supplier_compound_id'))}]"
                                    />
                                    <div class="invalid-feedback">{{formErrors.first('supplier_compound_id')}}</div>
                                </div><!-- /.form-group -->
                                <div class="form-group mb-1"
                                     v-if="inboundLoadFormState.orders[index].assignee_required > 0">
                                    <label class="d-block">{{$t('input.supervisor')}} *</label>
                                    <treeselect
                                        :multiple="false"
                                        :options="dropdowns.supervisors"
                                        placeholder=""
                                        v-model="inboundLoadFormState.orders[index].assignee_id"
                                        :class="[{'invalid is-invalid': (formErrors.has('assignee_id'))}]"
                                    />
                                    <div class="invalid-feedback">{{formErrors.first('assignee_id')}}</div>
                                </div><!-- /.form-group -->
                                <br>
                                <small v-if="inboundLoadFormState.orders[index].loading_factors > 0">
                                    {{$t('title.loadingFactors')}}:
                                    {{inboundLoadFormState.orders[index].loading_factors}}
                                </small>
                                <br>
                                <small v-if="group.does_m_l_approval_required" class="text-danger">
                                    {{$t('msc.thisLoadWillNeedAdditionalApprovalByManufacturer')}}
                                </small>
                                <br>
                                <small class="text-danger" v-if="inboundLoadFormState.orders[index].loading_factors > 0"
                                       v-html="inboundLoadFormState.orders[index].loading_factor_note"></small>
                                <small class="text-danger" v-if="!inboundLoadFormState.orders[index].price_id">
                                    {{ $t('msc.unableToDecidePrice') }}
                                </small>
                            </b-col>
                            <b-col lg="12" md="12" sm="12" class="mt-3">
                                <b-form-group
                                    :label="$t('input.comment')"
                                    label-for="comments"
                                    :invalid-feedback="formErrors.first('comments')"
                                >
                                    <b-form-textarea
                                        id="comments"
                                        v-model="inboundLoadFormState.orders[index].comments"
                                        :state="((formErrors.has('comments') ? false : null))"
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12" md="12" sm="12" class="mb-5">
                                <b-card class="mb-0">
                                    <b-card-header v-b-toggle="'inbound-load-documents-'+index" class="p-0">
                                        <h4 class="mb-0">
                                            <span class="badge badge-primary">
                                                {{$t('title.attachments')}}
                                            </span>
                                            <small v-show="inboundLoadFormState.orders[index].documents.length > 0">
                                                - {{inboundLoadFormState.orders[index].documents.length}} Item/s
                                            </small>
                                        </h4>
                                    </b-card-header><!-- /.p-0-->
                                    <b-collapse :id="`inbound-load-documents-${index}`">
                                        <div class="bg-light p-3">
                                            <b-row>
                                                <b-col lg="4" md="4" sm="12">
                                                    <div class="form-group">
                                                        <b-form-group
                                                            :label="$t('input.title')+' *'"
                                                            label-for="title"
                                                            :invalid-feedback="formErrors.first('title')"
                                                        >
                                                            <b-form-input
                                                                id="title"
                                                                v-model="inboundLoadFormState.orders[index].document.title"
                                                                type="text"
                                                                :state="((formErrors.has('title') ? false : null))"
                                                                @focus="$event.target.select()"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                        <div class="invalid-feedback">{{formErrors.first('title')}}
                                                        </div>
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                                <b-col lg="4" md="4" sm="12">
                                                    <b-form-group class="mt-4 pt-2">
                                                        <upload
                                                            v-model="inboundLoadFormState.orders[index].document.document"
                                                            :disabled="inboundLoadFormState.orders[index].document.document"
                                                            :title="(inboundLoadFormState.orders[index].document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                            css-class="mt-0 btn-sm"></upload>
                                                        <b-button :title="$t('msc.removeUpload')"
                                                                  type="button"
                                                                  variant="outline-primary"
                                                                  v-b-tooltip.hover
                                                                  class="ml-2 ml-2 btn-sm"
                                                                  @click="() => {inboundLoadFormState.orders[index].document.document = null;}"
                                                                  :disabled="!inboundLoadFormState.orders[index].document.document"
                                                                  v-if="inboundLoadFormState.orders[index].document.document">
                                                            <i class="fa fa-close"></i>
                                                        </b-button>
                                                        <b-button :title="$t('button.download')"
                                                                  v-b-tooltip.hover
                                                                  type="button"
                                                                  variant="outline-primary"
                                                                  class="ml-2 ml-2 btn-sm"
                                                                  v-if="inboundLoadFormState.orders[index].document.document && inboundLoadFormState.orders[index].document.document.download_url"
                                                                  :disabled="!(inboundLoadFormState.orders[index].document.document && inboundLoadFormState.orders[index].document.document.download_url)"
                                                                  :href="(inboundLoadFormState.orders[index].document.document ? inboundLoadFormState.orders[index].document.document.download_url : '')"
                                                                  target="_blank">
                                                            <i class="fa fa-cloud-download"></i>
                                                        </b-button>
                                                    </b-form-group>
                                                    <div class="invalid-feedback d-block">
                                                        {{formErrors.first('document')}}
                                                    </div>
                                                </b-col><!--/b-col-->
                                                <b-col lg="2" md="2" sm="12">
                                                    <div class="form-group">
                                                        <label class="d-block">
                                                            <pre> </pre>
                                                        </label>
                                                        <b-button variant="info" type="button" size="sm"
                                                                  class="btn-block"
                                                                  @click="handleAddUpdateInboundLoadDocumentClick(index)">
                                                            <i class="fa fa-plus"></i>
                                                        </b-button><!--/b-button-->
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                                <b-col lg="2" md="2" sm="12">
                                                    <div class="form-group">
                                                        <label class="d-block">
                                                            <pre> </pre>
                                                        </label>
                                                        <b-button variant="warning" type="button" size="sm"
                                                                  class="btn-block"
                                                                  @click="resetInboundLoadDocument(index)">
                                                            {{$t('button.reset')}}
                                                        </b-button><!--/b-button-->
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                            </b-row><!--/b-row-->
                                            <b-row>
                                                <b-col cols="12">
                                                    <table class="table table-bordered bg-white">
                                                        <thead>
                                                        <tr>
                                                            <th width="50">#</th>
                                                            <th width="180">{{$t('column.title')}}</th>
                                                            <th width="60">{{$t('column.action')}}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(ct, I) in inboundLoadFormState.orders[index].documents"
                                                            :class="[{'table-primary': ct.token === inboundLoadFormState.orders[index].document.token}]">
                                                            <td>{{I + 1}}</td>
                                                            <td>
                                                                {{ct.title}}
                                                            </td>
                                                            <td>
                                                                <a @click="handleEditInboundLoadDocumentClick(index, ct.token)"
                                                                   :title="$t('button.title.editItem')"
                                                                   v-b-tooltip.hover>
                                                                    <i class="fe fe-edit"></i>
                                                                </a>
                                                                <a :title="$t('button.download')" class=" ml-1"
                                                                   :href="ct.document.download_url"
                                                                   target="_blank" v-b-tooltip.hover>
                                                                    <i class="fa fa-cloud-download"></i>
                                                                </a>
                                                                <a-popconfirm title="Are you sure？"
                                                                              @confirm="handleDeleteInboundLoadDocumentClick(index, ct.token)">
                                                                    <i slot="icon" class="fe fe-trash"></i>
                                                                    <a class=" ml-1"
                                                                       :title="$t('button.title.deleteItem')"
                                                                       v-b-tooltip.hover>
                                                                        <i class="fe fe-trash"></i>
                                                                    </a>
                                                                </a-popconfirm>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                        <tfoot
                                                            v-show="inboundLoadFormState.orders[index].documents.length <= 0">
                                                        <tr>
                                                            <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                                        </tr>
                                                        </tfoot>
                                                    </table><!-- /.table table-bordered -->
                                                </b-col><!--/b-col-->
                                            </b-row><!--/b-row-->
                                        </div><!-- /.bg-light -->
                                    </b-collapse><!-- /#inbound-load-documents-->
                                </b-card><!-- /b-card -->
                            </b-col><!--/b-col-->
                        </b-row><!--/.row-->
                        <hr/>
                    </b-col><!--/.col-->
                </b-row><!--/.row-->

                <div class="drawer-footer">
                    <a-popconfirm title="Are you sure？" @confirm="handleInboundLoadSubmit()">
                        <a class="btn btn-sm btn-primary"
                           :disabled="global.pendingRequests > 0">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.save')}}
                        </a>
                    </a-popconfirm>

                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="handleInboundLoadFormOperationClose()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div><!--/.drawer-footer-->
            </form><!--/form-->
        </a-drawer><!--/.drawer-->

        <!-- # Start Priority -->
        <div class="priority-operation">
            <a-drawer
                placement="right"
                :width="'450px'"
                :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                :closable="false"
                @close="handlePriorityFormOperationClose(false)"
                :visible="priorityFormVisible"
                :zIndex="10"
                :title="$t('title.addPriority')"
            >
                <form @submit.prevent="handlePrioritySubmit(false)" autocomplete="off">
                    <b-row>
                        <b-col cols="12" v-show="global.pendingRequests > 0">
                            <a-skeleton active :paragraph="{ rows: 5 }"/>
                        </b-col>
                        <b-col cols="12" v-show="global.pendingRequests <= 0">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.priority')+' *'"
                                        label-for="client_affected_priority"
                                        :invalid-feedback="formErrors.first('client_affected_priority')">
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.priorities"
                                            placeholder=""
                                            v-model="priorityFormState.client_affected_priority"
                                            :class="[{'invalid is-invalid': (formErrors.has('client_affected_priority'))}]"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.comment')"
                                        label-for="client_affected_description"
                                        :invalid-feedback="formErrors.first('client_affected_description')"
                                    >
                                        <b-form-textarea
                                            id="client_affected_description"
                                            v-model="priorityFormState.client_affected_description"
                                            :state="((formErrors.has('client_affected_description') ? false : null))"
                                            @focus="$event.target.select()"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row><!--/b-row-->
                        </b-col><!--/b-col-->
                        <div class="drawer-footer">
                            <b-row>
                                <b-col sm="6" class="text-left">
                                    <b-form-group
                                        :invalid-feedback="formErrors.first('client_affected')"
                                    >
                                        <b-form-checkbox v-model="priorityFormState.client_affected"
                                                         name="client_affected">
                                            {{$t('input.clientAffected')}}
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="6">
                                    <b-button
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                    >
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        <i class="fa fa-save mr-1"></i>
                                        {{$t('button.save')}}
                                    </b-button>
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handlePriorityFormOperationClose(false)"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-row><!--/b-row-->
                </form><!--/form-->
            </a-drawer>
        </div><!--/.priority-operation-->

        <!-- # Excel Export -->
        <b-modal id="excel-export-modal" v-model="exportModal.visibility" hide-footer>
            <template #modal-title>
                {{$t('title.exportExcel')}}
            </template>
            <div>
                <form @submit.prevent="handleExcelExportSubmitClick">
                    <div class="mb-5">
                        <b-form-group>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportSelectAllClick">{{$t('input.checkAll')}}</a>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportUnSelectAllClick">{{$t('input.unCheckAll')}}</a>
                        </b-form-group>
                        <hr>
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('title.columns')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(column, index) in exportModal.columns">
                                <td>{{index + 1}}</td>
                                <td>
                                    <b-form-checkbox v-model="column.show"
                                                     name="check-button">{{column.label}}
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="drawer-footer">
                        <json-excel
                            class="btn btn-primary btn-sm"
                            :fields="exportModal.headers"
                            worksheet="Orders"
                            :fetch="handleExcelExportSubmitClick"
                            name="orders.xls">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.export')}}
                        </json-excel>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleExcelExportCancelClick()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal><!--/#excel-export-modal-->

        <!-- # Start Spot Inbound Load -->
        <a-drawer
            placement="right"
            :width="'86%'"
            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
            :closable="false"
            @close="handleSpotInboundLoadFormOperationClose"
            :visible="spotInboundLoadFormVisible"
            :zIndex="10"
            :title="$t('title.createSinglePickup')"
        >
            <form @submit.prevent="handleSpotInboundLoadSubmit" autocomplete="off">
                <b-row v-if="groupedSpotInboundOrders.length > 0">
                    <b-col sm="12" v-for="(group, index) in groupedSpotInboundOrders" :key="index">
                        <b-row>
                            <b-col sm="12" lg="2" md="2" class=" mb-sm-3">
                                <h4 class="d-sm-inline">
                                    <span class="bg-gray-6 ml-2 rounded text-white p-1">
                                        {{$t('title.load')}}#<strong>{{index + 1}}</strong>
                                    </span>
                                </h4>
                                <h4 class="mt-4 ml-2 d-sm-inline">
                                    <span class="bg-light rounded p-1">
                                        <small>{{$t('title.orders')}}-{{group.items.length}}</small>
                                    </span>
                                </h4>
                            </b-col>
                            <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                <dl>
                                    <dt>{{$t('title.fromLocation')}}:</dt>
                                    <dd>
                                        <print-ad :item="group.from_location"></print-ad>
                                    </dd><!--/FromLocation-->

                                    <dt>{{$t('title.toLocation')}}:</dt>
                                    <dd>
                                        <print-ad :item="group.to_location"></print-ad>
                                    </dd><!--/ToLocation-->
                                    <dt>VIN:</dt>
                                    <dd>
                                        <div v-for="(order, index) in group.items">
                                            {{order.vin_number}}
                                        </div>
                                    </dd>
                                </dl>
                            </b-col>
                            <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                <dl>
                                    <dt>{{$t('title.brand')}}:</dt>
                                    <dd>
                                        <div v-for="(order, index) in _.uniqBy(group.items, 'brand.title')">
                                            {{order.brand.title}}
                                        </div>
                                    </dd><!--/brand-->

                                    <dt>{{$t('title.model')}}:</dt>
                                    <dd>
                                        <div v-for="(order, index) in _.uniqBy(group.items, 'model.version_code')">
                                            {{order.model.title}}-{{order.model.version_code}}
                                        </div>
                                    </dd><!--/model-->
                                </dl>
                            </b-col>
                            <b-col sm="12" lg="3" md="3">
                                <div>
                                    <small v-if="spotInboundLoadFormState.orders[index].errors" class="mb-2 d-block">
                                        <span class="text-danger"
                                              v-html="spotInboundLoadFormState.orders[index].errors"></span>
                                    </small>
                                </div>
                                <div class="form-group mb-1">
                                    <label class="d-block">{{$t('input.supplierCarrier')}} *</label>
                                    <treeselect
                                        :multiple="false"
                                        :options="_.filter(dropdowns.carrierSuppliers, (supplier) => (
                                                        _.filter(supplier.prices, ({route}) => (
                                                            route.from_location.id === group.from_location.id &&
                                                            route.to_location.id === group.to_location.id
                                                        )
                                                    ).length > 0
                                                )
                                            )
                                        "
                                        placeholder=""
                                        v-model="spotInboundLoadFormState.orders[index].supplier_carrier_id"
                                        :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id') || formErrors.has('price_id') || formErrors.has('route_id'))}]"
                                        @input="(value, instance) => handleSpotSupplierChange(value, group, index)"
                                    >
                                        <template slot="value-label" slot-scope="{ node }">
                                            <div :title="node.label">
                                                {{ node.label }}
                                            </div>
                                        </template>
                                    </treeselect>
                                    <div class="invalid-feedback">{{formErrors.first('supplier_carrier_id')}}</div>
                                </div><!-- /.form-group -->
                                <div class="form-group mb-1">
                                    <label class="d-block">{{$t('input.supplierCompound')}} *</label>
                                    <treeselect
                                        :multiple="false"
                                        :options="dropdowns.compoundSuppliers"
                                        placeholder=""
                                        v-model="spotInboundLoadFormState.orders[index].supplier_compound_id"
                                        :class="[{'invalid is-invalid': (formErrors.has('supplier_compound_id'))}]"
                                    />
                                    <div class="invalid-feedback">{{formErrors.first('supplier_compound_id')}}</div>
                                </div><!-- /.form-group -->
                            </b-col>
                            <b-col lg="12" md="12" sm="12" class="mt-3">
                                <b-form-group
                                    :label="$t('input.comment')"
                                    label-for="comments"
                                    :invalid-feedback="formErrors.first('comments')"
                                >
                                    <b-form-textarea
                                        id="comments"
                                        v-model="spotInboundLoadFormState.orders[index].comments"
                                        :state="((formErrors.has('comments') ? false : null))"
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12" md="12" sm="12" class="mb-5">
                                <b-card class="mb-0">
                                    <b-card-header v-b-toggle="'inbound-load-documents-'+index" class="p-0">
                                        <h4 class="mb-0">
                                            <span class="badge badge-primary">
                                                {{$t('title.attachments')}}
                                            </span>
                                            <small v-show="spotInboundLoadFormState.orders[index].documents.length > 0">
                                                - {{spotInboundLoadFormState.orders[index].documents.length}} Item/s
                                            </small>
                                        </h4>
                                    </b-card-header><!-- /.p-0-->
                                    <b-collapse :id="`inbound-load-documents-${index}`">
                                        <div class="bg-light p-3">
                                            <b-row>
                                                <b-col lg="4" md="4" sm="12">
                                                    <div class="form-group">
                                                        <b-form-group
                                                            :label="$t('input.title')+' *'"
                                                            label-for="title"
                                                            :invalid-feedback="formErrors.first('title')"
                                                        >
                                                            <b-form-input
                                                                id="title"
                                                                v-model="spotInboundLoadFormState.orders[index].document.title"
                                                                type="text"
                                                                :state="((formErrors.has('title') ? false : null))"
                                                                @focus="$event.target.select()"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                        <div class="invalid-feedback">{{formErrors.first('title')}}
                                                        </div>
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                                <b-col lg="4" md="4" sm="12">
                                                    <b-form-group class="mt-4 pt-2">
                                                        <upload
                                                            v-model="spotInboundLoadFormState.orders[index].document.document"
                                                            :disabled="spotInboundLoadFormState.orders[index].document.document"
                                                            :title="(spotInboundLoadFormState.orders[index].document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                            css-class="mt-0 btn-sm"></upload>
                                                        <b-button :title="$t('msc.removeUpload')"
                                                                  type="button"
                                                                  variant="outline-primary"
                                                                  v-b-tooltip.hover
                                                                  class="ml-2 ml-2 btn-sm"
                                                                  @click="() => {spotInboundLoadFormState.orders[index].document.document = null;}"
                                                                  :disabled="!spotInboundLoadFormState.orders[index].document.document"
                                                                  v-if="spotInboundLoadFormState.orders[index].document.document">
                                                            <i class="fa fa-close"></i>
                                                        </b-button>
                                                        <b-button :title="$t('button.download')"
                                                                  v-b-tooltip.hover
                                                                  type="button"
                                                                  variant="outline-primary"
                                                                  class="ml-2 ml-2 btn-sm"
                                                                  v-if="spotInboundLoadFormState.orders[index].document.document && spotInboundLoadFormState.orders[index].document.document.download_url"
                                                                  :disabled="!(spotInboundLoadFormState.orders[index].document.document && spotInboundLoadFormState.orders[index].document.document.download_url)"
                                                                  :href="(spotInboundLoadFormState.orders[index].document.document ? spotInboundLoadFormState.orders[index].document.document.download_url : '')"
                                                                  target="_blank">
                                                            <i class="fa fa-cloud-download"></i>
                                                        </b-button>
                                                    </b-form-group>
                                                    <div class="invalid-feedback d-block">
                                                        {{formErrors.first('document')}}
                                                    </div>
                                                </b-col><!--/b-col-->
                                                <b-col lg="2" md="2" sm="12">
                                                    <div class="form-group">
                                                        <label class="d-block">
                                                            <pre> </pre>
                                                        </label>
                                                        <b-button variant="info" type="button" size="sm"
                                                                  class="btn-block"
                                                                  @click="handleAddUpdateSpotInboundLoadDocumentClick(index)">
                                                            <i class="fa fa-plus"></i>
                                                        </b-button><!--/b-button-->
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                                <b-col lg="2" md="2" sm="12">
                                                    <div class="form-group">
                                                        <label class="d-block">
                                                            <pre> </pre>
                                                        </label>
                                                        <b-button variant="warning" type="button" size="sm"
                                                                  class="btn-block"
                                                                  @click="resetSpotInboundLoadDocument(index)">
                                                            {{$t('button.reset')}}
                                                        </b-button><!--/b-button-->
                                                    </div><!-- /.form-group -->
                                                </b-col><!--/b-col-->
                                            </b-row><!--/b-row-->
                                            <b-row>
                                                <b-col cols="12">
                                                    <table class="table table-bordered bg-white">
                                                        <thead>
                                                        <tr>
                                                            <th width="50">#</th>
                                                            <th width="180">{{$t('column.title')}}</th>
                                                            <th width="60">{{$t('column.action')}}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(ct, I) in spotInboundLoadFormState.orders[index].documents"
                                                            :class="[{'table-primary': ct.token === spotInboundLoadFormState.orders[index].document.token}]">
                                                            <td>{{I + 1}}</td>
                                                            <td>
                                                                {{ct.title}}
                                                            </td>
                                                            <td>
                                                                <a @click="handleEditSpotInboundLoadDocumentClick(index, ct.token)"
                                                                   :title="$t('button.title.editItem')"
                                                                   v-b-tooltip.hover>
                                                                    <i class="fe fe-edit"></i>
                                                                </a>
                                                                <a :title="$t('button.download')" class=" ml-1"
                                                                   :href="ct.document.download_url"
                                                                   target="_blank" v-b-tooltip.hover>
                                                                    <i class="fa fa-cloud-download"></i>
                                                                </a>
                                                                <a-popconfirm title="Are you sure？"
                                                                              @confirm="handleDeleteSpotInboundLoadDocumentClick(index, ct.token)">
                                                                    <i slot="icon" class="fe fe-trash"></i>
                                                                    <a class=" ml-1"
                                                                       :title="$t('button.title.deleteItem')"
                                                                       v-b-tooltip.hover>
                                                                        <i class="fe fe-trash"></i>
                                                                    </a>
                                                                </a-popconfirm>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                        <tfoot
                                                            v-show="spotInboundLoadFormState.orders[index].documents.length <= 0">
                                                        <tr>
                                                            <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                                        </tr>
                                                        </tfoot>
                                                    </table><!-- /.table table-bordered -->
                                                </b-col><!--/b-col-->
                                            </b-row><!--/b-row-->
                                        </div><!-- /.bg-light -->
                                    </b-collapse><!-- /#inbound-load-documents-->
                                </b-card><!-- /b-card -->
                            </b-col><!--/b-col-->
                        </b-row><!--/.row-->
                        <hr/>
                    </b-col><!--/.col-->
                </b-row><!--/.row-->

                <div class="drawer-footer">
                    <a-popconfirm title="Are you sure？" @confirm="handleSpotInboundLoadSubmit()">
                        <a class="btn btn-sm btn-primary"
                           :disabled="global.pendingRequests > 0">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.save')}}
                        </a>
                    </a-popconfirm>

                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="handleSpotInboundLoadFormOperationClose()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div><!--/.drawer-footer-->
            </form><!--/form-->
        </a-drawer><!--/.drawer-->

        <!-- # start update status -->
        <a-drawer
            placement="right"
            :width="'360px'"
            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
            :closable="false"
            @close="handleQualityHoldStatusUpdateClose"
            :visible="statusFormState.visible"
            :zIndex="10"
            :title="$t('title.updateStatus')"
        >
            <form @submit.prevent="handleQualityHoldStatusSubmit" autocomplete="off">
                <b-row>
                    <b-col sm="12">
                        <b-form-group
                                :label="$t('input.status')+' *'"
                                label-for="status"
                                :invalid-feedback="formErrors.first('status')">
                            <treeselect
                                :multiple="false"
                                :options="[{id: -2, label: $t('title.qualityHoldBlocked')}, {id: 1, label: $t('title.produced')}]"
                                placeholder=""
                                v-model="statusFormState.status"
                                :class="[{'invalid is-invalid': (formErrors.has('status'))}]"
                            />
                        </b-form-group>
                    </b-col><!--/b-col-->
                    <b-col sm="12">
                        <b-form-group
                            :label="$t('input.comment')"
                            label-for="comment"
                            :invalid-feedback="formErrors.first('comments')"
                        >
                            <b-form-textarea
                                id="comment" v-model="statusFormState.comments"
                                :state="((formErrors.has('comments') ? false : null))"
                                @focus="$event.target.select()"></b-form-textarea>
                        </b-form-group>
                    </b-col><!--/b-col-->
                </b-row>
                <div class="drawer-footer">
                    <b-button variant="primary" class="ml-3"
                              :disabled="global.pendingRequests > 0"
                              size="sm" type="submit"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                     size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.save')}}
                    </b-button>
                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="handleQualityHoldStatusUpdateClose()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div><!--/.drawer-footer-->
            </form><!--/form-->
        </a-drawer><!--/.drawer-->
    </div><!--/div-->
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../util/Request'
    import CreateInboundLoadMixin from './CreateInboundLoadMixin'
    import ExcelExportMixin from './ExcelExportMixin'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'
    import CreatePriorityMixin from "./CreatePriorityMixin"
    import JsonExcel from "vue-json-excel"
    import CreateStatusUpdateMixin from "./CreateStatusUpdateMixin";
    import CreateSpotInboundLoadMixin from "./CreateSpotInboundLoadMixin";

    const FORM_STATE = {
        client_id: null,
        brand_id: null,
        model_id: null,
        from_location_id: null,
        to_location_id: null,
        dealer_id: null,
        dealer_location_id: null,
        order_id: null,
        mpo_number: null,
        invoice_number_importer: null,
        invoice_date_importer: null,
        manufacturer_invoice_date: null,
        comments: null,
        status: null,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        has_manufacturer_invoice_date: 1,
        brands: [],
        models: [],
        modelsGroupByTitle: [],
        configCodes: [],
        dealers: [],
        clients: [],
        status: [1, -2],
        fromLocations: [],
        toLocations: [],
        is_manual: [0],
        manufacturer_invoice_date: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.brand'),
            key: 'brand_id',
            sortable: false,
            stickyColumn: true,
            class: 'w-120'
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.vin'),
            key: 'vin_number',
            sortable: true,
        },
        {
            label: self.$t('column.model'),
            key: 'model',
            sortable: true,
        },
        {
            label: self.$t('column.market'),
            key: 'order_country',
            sortable: true,
        },
        {
            label: self.$t('column.dateOfAvailability'),
            key: 'manufacturer_invoice_date',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'load_status',
            sortable: true,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['ordersupdate'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    const STATUS_UPDATE_STATE = {
        id: null,
        status: null,
        comments: null,
        visible: false
    }

    export default {
        mixins: [ListingMixin, CreateInboundLoadMixin, CreateSpotInboundLoadMixin, ExcelExportMixin, CreatePriorityMixin],
        components: {
            Treeselect,
            Detail,
            JsonExcel
        },
        data() {
            return {
                operationTitle: 'title.orders',
                formFields: {...FORM_STATE},
                formErrors: new Error({}),
                filters: {...FILTER_STATE},
                listUrl: 'orders',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    countries: [],
                    clients: [],
                    brands: [],
                    models: [],
                    modelsGroupByTitle: [],
                    configCodes: [],
                    dealers: [],
                    supervisors: [],
                    dealersLocations: [],
                    supplierCompoundLocations: [],
                    manufacturerLocations: [],
                    currencies: [],
                    status: [
                        {id: -3, label: this.$t('title.pending')},
                        {id: -2, label: this.$t('title.qualityHoldBlocked')},
                        {id: -1, label: this.$t('title.confirmationOrder')},
                        {id: 1, label: this.$t('title.produced')},
                        {id: 2, label: this.$t('title.addedToLoad')},
                        {id: 3, label: this.$t('title.assignedToLoad')},
                        {id: 4, label: this.$t('title.loaded')},
                        {id: 5, label: this.$t('title.inTransit')},
                        {id: 6, label: this.$t('title.unloadRequested')},
                        {id: 7, label: this.$t('title.unloaded')},
                        {id: 8, label: this.$t('title.stocked')},
                        {id: 9, label: this.$t('title.hold')},
                        {id: 10, label: this.$t('title.transportOrder')},
                        {id: 11, label: this.$t('title.announced')},
                        {id: 12, label: this.$t('title.rejected')},
                        {id: 13, label: this.$t('title.confirmed')},
                        {id: 14, label: this.$t('title.pickUp')},
                        {id: 15, label: this.$t('title.stockExitInTransit')},
                        {id: 16, label: this.$t('title.deliveryRequested')},
                        {id: 17, label: this.$t('title.delivered')},
                    ],
                    priorities: [
                        {id: 1, label: this.$t('title.high')},
                        {id: 2, label: this.$t('title.medium')},
                        {id: 3, label: this.$t('title.low')}
                    ],
                    locations: [],
                    valueConsistsOf: 'BRANCH_PRIORITY',
                },
                show: true,
                priorityFormVisible: false,
                inboundLoadFormVisible: false,
                selectedOrderLength: 0,
                selectedOrders: [],
                spotInboundLoadFormVisible: false,
                spotInboundSelectedOrders: [],
                groupedSpotInboundOrders: [],
                selectedSpotInboundOrderLength: 0,
                selectAllBrand: false,
                statusFormState: {...STATUS_UPDATE_STATE}
            }
        },
        mounted() {
            this.getClients();
            this.getDealers();
            this.getBrands();
            this.getModels();
            this.getSupervisors();
            this.getManufacturerLocations();
            this.getSupplierCompoundLocations();
            this.getCountries();
            this.getCurrencies();
            this.getLocations();

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('topBar.navigations.modules.orders')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            handleRowClick(item, rowIndex, event) {
                this.handleLoadRowClick(item, rowIndex, event);
                if(item.load_status > 0) {
                    this.handleInboundRowSelection(item, rowIndex, event);
                    this.handleSpotInboundRowSelection(item, rowIndex, event);
                }
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'orders/update' : 'orders/create',
                        method: 'post',
                        data: {...this.formFields},
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    } else {
                        this.itemAdded()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/orders/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (errors) {
                    this.itemDeleteFails()
                }
            },
            async handleEditClick(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/orders/detail/${id}`,
                    })
                    this.operationTitle = this.$t('title.editOrder')
                    const {data} = response
                    const {client, brand, dealer, model, from_location, to_location, dealer_location} = data
                    delete data.client
                    delete data.brand
                    delete data.model
                    delete data.from_location
                    delete data.to_location
                    delete data.dealer_location
                    this.formFields = {
                        ...data,
                        client_id: (client ? client.id : null),
                        brand_id: (brand ? brand.id : null),
                        model_id: (model ? model.id : null),
                        dealer_id: (dealer ? dealer.id : null),
                        to_location_id: (to_location ? to_location.id : null),
                        from_location_id: (from_location ? from_location.id : null),
                        dealer_location_id: (dealer_location ? dealer_location.id : null),
                        edit_dealer_location_id: (dealer_location ? dealer_location.id : null),
                    }
                    this.handleDealersSelect()
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data
                    this.dropdowns.modelsGroupByTitle = _.uniqBy(data.map(item => ({...item, id: item.label})), 'id');
                    this.dropdowns.configCodes = _.uniqBy(data.map(item => ({ id: item.version_code, label: item.version_code, model_id: item.id, model_label: item.label })), 'id')
                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCodes = []
                }
            },
            async getClients() {
                try {
                    const response = await request({
                        url: '/dropdowns/clients',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.clients = data

                } catch (e) {
                    this.dropdowns.clients = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = data
                } catch (e) {
                    this.dropdowns.dealers = []
                    this.dropdowns.dealersLocations = []
                }
            },
            async getManufacturerLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/manufacturer/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.manufacturerLocations = data

                } catch (e) {
                    this.dropdowns.manufacturerLocations = []
                }
            },
            async getSupplierCompoundLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/supplier/compound/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCompoundLocations = _.uniqBy(data, 'id')
                } catch (e) {
                    this.dropdowns.supplierCompoundLocations = []
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post",
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            async getSupervisors() {
                try {
                    const response = await request({
                        url: '/dropdowns/supervisors',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supervisors = data

                } catch (e) {
                    this.dropdowns.supervisors = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            handleQualityHoldStatusUpdate(record) {
                if(record.load_status == 1 || record.load_status == -2) {
                    this.statusFormState.id = record.id;
                    this.statusFormState.status = record.load_status;
                    this.statusFormState.visible = true;
                }
            },
            handleQualityHoldStatusUpdateClose() {
                this.statusFormState = {...STATUS_UPDATE_STATE};
            },
            async handleQualityHoldStatusSubmit() {
                try {
                    const response = await request({
                        url: 'orders/update/status/hold',
                        method: 'post',
                        data: {...this.statusFormState},
                    })

                    this.itemUpdated()
                    this.refreshList()
                    this.handleQualityHoldStatusUpdateClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('ordersview')
            },
            refreshList() {
                this.loadList()
            },
            listLoaded() {
                this.handlePriorityFormOperationClose(false)
                this.handleInboundLoadFormOperationClose(false)
                this.handleSpotInboundLoadFormOperationClose(false)
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
            handleDealersSelect() {
                const self = this
                self.dropdowns.dealersLocations.length = 0;
                self.formFields.dealer_location_id = null;
                setTimeout(() => {
                    const locations = []
                    self.formFields.dealer_location_id = null;
                    self.dropdowns.dealers.map((item) => {
                        item.locations.map((lc) => {
                            locations.push({...lc, dealer_id: item.id, label: `${item.label} - ${lc.label}`})
                        })
                    })

                    self.dropdowns.dealersLocations = _.uniqBy(locations.filter((item) => item.dealer_id === self.formFields.dealer_id), 'id')
                    self.formFields.dealer_location_id = self.formFields.edit_dealer_location_id
                    self.formFields.edit_dealer_location_id = null
                }, 500)
            },
            handleSelectModels(changeValue) {
                this.filters.configCodes = []
            },
            handleSelectAllModels(e) {
                this.$nextTick(() => {
                    if (e.target.checked) {
                        let data = _.uniqBy(_.filter(_.uniqBy(this.dropdowns.modelsGroupByTitle, 'label'), ({brand_id}) => _.every([_.includes(this.filters.brands, brand_id)])), 'id');
                        this.filters.modelsGroupByTitle = _.map(data,(i) => i.id)
                    } else {
                        this.filters.modelsGroupByTitle = [];
                    }
                })
            },
            handleSelectBrands(changeValue) {
                this.selectAllBrand = false
                this.filters.modelsGroupByTitle = []
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .w-120 {
        width: 120px;
    }

    .w-220 {
        width: 220px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 1379px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }
</style>
